@import '../../components/variables.less';

.home {
  &__hero {
    &__text {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 100%;
      max-width: 40rem;
    }

    &--subtitle {
      color: @mainGray;
      font-family: 'Nunito', sans-serif;
      font-size: 1rem;
      font-weight: 700;
      line-height: 2.2rem;
      letter-spacing: 0em;
    }

    &--title {
      color: @secondaryLight;
      font-family: 'Lora', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 3rem;
      line-height: 3rem;
      margin-bottom: 1rem;
    }
  }

  &__video {
    display: flex;
    flex-wrap: wrap;
    margin: 5rem 0;

    &--item {
      width: 100%;
      aspect-ratio: 16/9;
      margin-top: 2rem;
    }

    &--iframe {
      border-radius: 1rem;
    }

    &--btn {
      margin-top: 3rem;
    }

    &--title {
      font-family: 'Lora', sans-serif;
      font-size: 2rem;
      font-weight: 400;
      line-height: 2.5rem;
      letter-spacing: 0em;
    }

    &--text {
      color: @mainGray;
      font-family: 'Nunito', sans-serif;
      font-size: 1.1rem;
      font-weight: 400;
      line-height: 1.7rem;
      letter-spacing: 0em;
    }
  }

  &__songs {
    display: flex;
    flex-direction: column-reverse;

    &__spinner {
      margin: 1rem auto;
      width: 5rem;
    }

    &--title {
      color: @brow;
      font-family: 'Lora', sans-serif;
      font-size: 3rem;
      font-weight: @light;
      line-height: 4rem;
      letter-spacing: 0em;
    }

    &__button {
      display: flex;
      justify-content: center;
      margin-top: 2rem;
    }

    &__ellipsis {
      position: absolute;
      left: 0%;

      background: @ellipsis;
      filter: blur(200px);
      z-index: -1;
    }

    &__sign-up {
      align-items: center;
      display: flex;
      flex-direction: column;
      margin-top: 3rem;
      margin-bottom: 0;
      width: 70%;

      &--btn {
        margin-bottom: 2rem;;
      }

      &--text {
        font-family: 'Nunito', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 1rem;
        line-height: 150%;
      }
    }
  }

  &__info {
    background-color: @yellowLight;
    padding: 5rem 1rem;
    display: flex;
    justify-content: center;

    &--title {
      color: @thirdGray;
      font-family: 'Lora', sans-serif;
      font-size: 3.4rem;
      font-weight: 400;
      line-height: 4.3rem;
      text-align: center;
    }

    &__item {
      align-items: center;
      color: @mainGray;
      display: flex;
      font-family: 'Nunito', sans-serif;
      font-size: 1.4rem;
      font-weight: 700;
      line-height: 2.1rem;
      letter-spacing: 0em;
      margin-bottom: 1.5rem;

      &--img {
        margin-right: 1rem;
      }

    }
  }

}

@media (min-width: @mobile) {
  .home {
    &__songs {
      &__ellipsis {
        left: 45%;
      }

      &--title {
        font-size: 3.4rem;
        line-height: 4.3rem;
      }
    }

    &__hero {
      &--title {
        font-size: 4.5rem;
        line-height: 5.8rem;
      }

      &--subtitle {
        font-size: 1.4rem;
        line-height: 2.2rem;
      }
    }

    &__video {
      &--title {
        font-size: 2.4rem;
        line-height: 2.9rem;
      }
    }
  }
}

@media (min-width: @tablet) {
  .home {

    &__video {
      justify-content: space-between;
      &--item {
        margin: 0;
        width: 48%;
      }
    }

    &__hero {
      &__text {
        width: 70%;
      }
    }

    &__info {
      padding: 5rem 0;
    }

    &__songs {
      flex-direction: column;
      &__ellipsis {
        left: 65%;
      }

      &__sign-up {
        align-items: start;
        margin-bottom: 3rem;
        margin-top: 0;
      }
    }
  }
}
