@import "../variables.less";

.layout {
  &__sign-up {
    align-items: center;
    background: @yellowGradient;
    display: flex;
    flex-direction: column;
    height: 15.5rem;
    justify-content: center;

    &__text {
      color: @brow;
      font-family: 'Lora', sans-serif;
      font-style: normal;
      font-weight: 400;
      line-height: 41px;
      font-size: 2.2rem;
      margin-bottom: 2rem;
      text-align: center;
    }
  }

}

@media (min-width: @tablet) {
  .mandolin-img {
    background-image: url('../../../../assets/img/mandolin.png');
    background-repeat: no-repeat;
    background-size: 48.3rem;
    background-position: 100% 0;
  }

  .layout {
    &__sign-up {
      &__text {
        text-align: initial;
      }
    }
  }
}
