@import "../../variables.less";

.header {
  align-items: center;
  background-color: transparent;
  display: flex;
  justify-content: space-between;
  padding: .3rem;
  font-family: 'Nunito', sans-serif;
  font-weight: @heavy;
  font-size: @normalTwoFont;
  text-transform: uppercase;

  &__logo {
    width: 10rem;
    z-index: 1;
    & img {
      height: auto;
    }
  }
  &__links {
    align-items: center;
    background: @white;
    bottom: -15px;
    display: flex;
    flex-direction: column;
    height: 100vh;
    justify-content: center;
    left: 0px;
    list-style: none;
    position: fixed;
    width: 100vw;
    z-index: 99;

    &__desktop {
      bottom: 100%;
    }

    & li a {
      color: @secondaryColor;
      margin: 1rem 0;
      text-align: center;
      width: 12rem;
    }

    &__dropdown {
      width: auto;
      & .dropdown.icon {
        display: none;
      }

      &.ui.dropdown .menu {
        left: -15px;
        margin-top: .5rem;
      }
    }
  }

  &__icon {
    z-index: 999;

    &__close {
      top: 15px;
      position: fixed;
      right: 15px;
    }
  }

  &__ellipse {
    background: @ellipsis;
    filter: blur(200px);
    height: 290px;
    position: fixed;
    top: 0;
    width: 290px;
    z-index: -1;

    &--right {
      right: 15%;
    }

    &--left {
      left: 0;
    }
  }
}

@media (min-width: @tablet) {
  .header {
    &__logo {
      margin: 0;

      & img {
        max-width: 90%;
      }
    }
    &__links {
      background-color: transparent;
      flex-direction: row;
      height: auto;
      position: static;
      width: auto;
      & * > {
        margin: 0 0 0 1rem;
        width: auto;
      }
    }
  }
}
