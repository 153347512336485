@import '../../variables.less';
.container {
  margin: 0 auto;
  width: 96%;
}

@media (min-width: @mobile) {
  .container {
    width: 90%;
  }
}

@media (min-width: @tablet) {
  .container {
    width: 70%;
  }
}

@media (min-width: @wideScreenDesktop) {
  .container {
    width: 50%;
  }
}
