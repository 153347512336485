@import "../../variables.less";

.reset{
  &__button{
    width: 100%;
  }

  &__link {
    color: @secondaryColor;
    font-family: 'Nunito', sans-serif;
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    margin-top: 2rem;
    text-decoration: underline;
    text-align: center;

    &:hover {
      color: @primaryColor;
      text-decoration: underline;
    }
  }

  &__form {
    display: flex;
    flex-direction: column;
    justify-content: space-center;
  }
}
