@import '../variables.less';

.songs {

  &__container {
    padding: 1rem 0;
  }
  &__header {
    align-items: center;
    display: flex;
    justify-content: left;

    & h3 {
      margin: 0 1rem;
    }
  }
  &__title {
    font-family: 'Lora';
    font-style: normal;
    font-weight: 400;
    font-size: 64px;
    line-height: 82px;
    color: @thirdGray;
    padding: 2rem 0rem 0rem 0rem;
    margin: 0rem;
  }

  &__filters {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 0.5rem;

    &__dropdown {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-end;
      & > .ui.dropdown {
        margin-right: 1rem;
        //font style//
        font-family: 'Nunito';
        font-style: normal;
        font-weight: 600;
        font-size: 1rem;
        letter-spacing: 0.05em;
        text-transform: uppercase;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      & .dropdown.icon::before {
        color: @primaryColor;
      }

      & > .ui.dropdown:not(.button) > .default.text {
        color: @thirdGray;
      }
    }
  }

  &__input {
    &.ui.input{
      & * > {
        margin-bottom: 2rem;
      }
    }
    &.ui.icon.input {
      width: 100%;
      & > input {
        padding-left: 3rem;
        background: #FFFFFF;
        border: 1px solid #6C6C6C;
        border-radius: 35px;
        display:flex;
        justify-content:space-between;
        height: 40px;

      }
    }
    &.ui.icon.input > i.icon {
      filter: initial;
      right: initial;
      top: -1rem;
    }
  }

  &__dropdown__style {
    background: @white;
    border: 1px solid @mainGray;
    border-radius: 8px;
    padding: .1rem 1rem;
    margin-bottom: .4rem;

  }
  &__dropdown__level {
    background: @white;
    border: 1px solid @mainGray;
    border-radius: 8px;
    padding: .1rem 1rem;
    margin-bottom: .4rem;
  }
  &__dropdown__key {
    background: @white;
    border: 1px solid @mainGray;
    border-radius: 8px;
    padding: .1rem 1rem;
    margin-bottom: .4rem;
  }

  &__control {
    &.ui.input,.ui.dropdown {
      & * > {
        border-radius: 20px;
        height: 1.8rem;
        margin: .5rem 0;
      }
    }
  }
  &__name {
    justify-content: center;
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    /* identical to box height, or 114% */
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: @mainGray;
    margin: 0.5rem 0rem 1rem .5rem;
    padding: 0rem;
  }
}
.song-title{
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1rem;
  /* identical to box height, or 143% */
  color: @seventhGray;
}
.song-style{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  text-align: center;
  line-height: 1rem;
  /* identical to box height, or 143% */
  color: @seventhGray;
}
.song-key{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1rem;
  /* identical to box height, or 143% */
  color: @seventhGray;
}

.radio-stack{
  display: flex;
  background: @mainGray;
  border: 1px solid @mainGray;
  border-radius: 50%;
  margin: 0 .5rem;
}
.radio-child{
  text-align: center;
  border-radius: 50%;
  height: 1.5rem;
  width: 1.5rem;
}
.level-icon{
  margin: .3rem 0rem 0rem 0rem;
  height: 1rem;
  width: 0.9rem;
}
.radio{
  margin: .3rem .5rem 0rem .5rem;
  height: 1.2rem;
  width: 1.2rem;
  border: 1px solid @mainGray;
}
.radio-stack-small{
  display: flex;
  background: @mainGray;
  border: 1px solid @mainGray;
  border-radius: 50%;
  margin: 0 0.3rem;
}
.radio-child-small{
  text-align: center;
  border-radius: 50%;
  height: 1.1rem;
  width: 1.1rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.level-icon-small{
  height: 0.7rem;
  width: 0.7rem;
}
.radio-small{
  margin: 0 .3rem;
  height: 0.9rem;
  width: 0.9rem;
  border-radius: 50%;
  border: 1px solid @mainGray;
}

@media (min-width: @mobile) {
  .songs {
    &__input {
      &.ui.icon.input {
        margin: 0 auto;
        width: 100%;
        padding: 0rem;
      }
    }

    &__dropdown {
      & > .ui.dropdown {
        margin-left: 2rem;
      }
    }

    &__filters {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      margin-bottom: 0.5rem;
      &__dropdown {
        justify-content: space-between;

      }
    }
  }
}


