@import '../variables.less';

.video-player {

  height: 100%;
  width: 100%;
  padding: 0;

  &__full-screen {
    align-items: center;
    display:flex;
    padding: 0;
  }

  &__content {
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;

    &--bordered {
      border: 3px solid @borderPlayer;
    }
  }
  &__stage {
    background-color: @white;
    z-index: 10;
    margin: 0 auto;

    & > {
      width: 100% !important;
    }
  }

  &__video-container {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__tab-bar {
    background-color: white;
    display: flex;
    flex-direction: column;
    .play-pause-icons {
      color: @primaryColor;
      height: 1.25rem;
    }
    .restart-icon {
      height: 1.5rem;
    }
  }

  &__control-bar {
    border: 1px solid @mainGray;
    border-radius: .5rem;
    display: flex;
    flex-direction: column;
    width: 100%;

    &--track {
      align-items: center;
      background-color: @white;
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      justify-content: space-between;
      padding: 0.5rem 0.75rem;
      width: 100%;
    }

    &--info {
      background-color: #EDEDED;
      border-bottom-left-radius: 0.5rem;
      border-bottom-right-radius: 0.5rem;
      color: @thirdGray;
      font-family: 'Nunito', sans-serif;
      font-style: normal;
      line-height: 1.1rem;
      letter-spacing: 0.05em;
      padding: 0.5rem 0.75rem;
      width: 100%;

      & p {
        display: initial;
        font-size: 1rem;
        font-weight: 600;
      }

      & span {
        font-size: 1.1rem;
        font-weight: 700;
        font-style: bold;
      }
    }

    &.Safari13 {
      padding: 1.5rem 0.75rem;
    }

    &--time {
      font-family: 'Nunito', sans-serif;
      font-size: 1.14rem;
      font-weight: 700;
      line-height: 1.14rem;
      letter-spacing: 0.05em;

    }
  }

  &__controls {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;
    z-index: 20;
  }

  &__title {
    display: none;
  }

  &__audio-options {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    order: 2;
  }
  &__full-screen {
    order: 3;
  }

  &__backing-track-indicator {
    margin: 0;
    line-height: 1;
  }

  &__backing-track-toggler {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.5rem;
  }

  .fade-out > * {
    opacity: 0;
    transition: all 1.5s;
  }

  &:hover {
    .video-player__tab-bar > * {
      opacity: 1;
      transition: all 1.5s;
    }
  }

  &__speed-picker {
    &.ui.button {
      align-items: center;
      border: 1px solid @mainGray;
      border-radius: 0.5rem;
      display: flex;
      font-family: 'Nunito', sans-serif;
      font-size: 1rem;
      font-weight: 600;
      justify-content: space-between;
      line-height: 1.1rem;
      letter-spacing: 0.05em;
      padding: 0.7rem 1.5rem;
    }

    &.ui.dropdown > .text {
      margin-right: 0;
    }

    & i {
      color: @primaryColor;
    }
    &--full-screen {
      &.ui.button {
        font-size: 1rem;
      }
    }
  }

  &__progress-bar {
    &.ui.orange {
      color: @primaryColor;
    }
    & .ui.progress {
      font-size: 0.35rem;
    }
    &:hover {
      cursor: pointer;
    }
    &:active {
      cursor: grabbing;
    }
  }

  &__media-controls {
    display: flex;
  }

  &--full-screen {
    align-items: center;
    display: flex;
  }
}

.ui.progress.red {
  margin: 0;
}

.ui.progress:first-child {
  margin: 0;
}

.ui.red.progress {
  cursor: pointer;
  background-color: gray;

  .bar {
    height: 10px !important;
  }
}

.ui.toggle.checkbox .box:before,
.box:hover::before,
.ui.toggle.checkbox label:before,
.ui.toggle.checkbox .box:hover::before,
.ui.toggle.checkbox label:hover::before,
.ui.toggle.checkbox input:focus ~ .box:before,
.ui.toggle.checkbox input:focus ~ label:before {
  background: gray;
  background-color: gray !important;

  &:checked, &:hover {
    background: gray;
    background-color: gray !important;
  }
}

.ui.toggle.checkbox .box:before, .ui.toggle.checkbox label:before {
  background-color: gray;
}

.ui.toggle.checkbox input:focus~label:before, .ui.toggle.checkbox label:hover:before {
  background-color: gray !important;
}

.ui.labeled.icon.buttons .button,
.ui.labeled.icon.button {
  color: gray;
  background: transparent !important;

  &:hover {
    color: lightgray;
  }
}

.no-hover {
  pointer-events: none;
}

@media (min-width: 420px) {
  .video-player__title {
    display: block;
    margin: 0;
    line-height: 1;
    padding: 0 1.5rem;
    text-align: center;
    font-size: 1rem;
  }
}

// hardcoded value accounts for container width
@media all and (max-width: 1060px) {
  .video-player {
    &__media-controls {
      display: contents;
    }
    &__audio-options {
      width: 100%;
      order: 3;
      gap: 0.7rem;
      padding: 0;
      margin-top: 0.5rem;
    }
    &__full-screen {
      order: 2;
    }
  }
}

@media (min-width: @mobile) {
  .video-player {
    &__speed-picker {
      &.ui.button {
        justify-content: initial;
      }

      &.ui.dropdown > .text {
        margin-right: 1.5rem;;
      }
    }
    &__audio-options {
      display: flex;
      flex-direction: row;
      gap: 1rem;
    }
  }
}
