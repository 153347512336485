@import "variables.less";

@font-face {
  font-family: 'Local - Avenir Next Medium';
  src: local('Local - Avenir Next Medium'), url(../fonts/avenir-next/Avenir\ Next\ W10\ Medium.ttf) format('truetype');
}

body, pre {
  font-family: 'Roboto', helvetica, arial, sans-serif;
  font-weight: 500;
  min-height: 100%;
  word-wrap: break-word;
  background:transparent;
}

pre {
  text-align: center;
  font-weight: @lighter;
}

#root { min-height: 100%; }

h4 {
  font-size: 1.2rem;
  margin: 0;
}

figure, p {
  margin: 0;
}

a {
  color: @primaryColor;
}

.section {
  margin: 4rem 0;
}

.group {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.bc__gray {
  background-color: @mainGray;
  &--light {
    background-color: @softGray;
  }
}

.bc__dark {
  background: @darkBackground;
}

.bc__secondary {
  background: @secondaryColor;
}


.text__gray {
  color: @mainGray;
}

.modal > * {
  background: @black !important;
}

.modal > *, .ui.modal > .header, .ui.form .field > label {
  color: @mainGray !important;
  font-family: 'Nunito', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  /* identical to box height, or 114% */
  letter-spacing: 0.05em;
  text-transform: uppercase;
  padding-bottom: 0.3rem;


}

.ui.pagination.menu {
  cursor: pointer !important;
  background: @black !important;
  opacity: 0.9;
  border-radius: 1rem;
  border: 1.5px @gray solid !important;
  box-shadow: 1.2px 1.2px 3px 1.2px @gray;

  .item, .item:hover {
    color: white;
  }

  .item:hover {
    background: @gray;
  }

  .active.item {
    background: @gray !important;
    color: white;
  }
}

.form {
  &--block {
   padding: 0.5rem 2.5rem 2.5rem 2.5rem !important;
   max-width: 416px;
   background-color:@white;
   border-radius: 8px;
   box-shadow: 0px 8px 24px @black;
   border: 1px solid @fourthGray;
  }

  & a h4{
    font-family: 'Nunito', sans-serif;
    font-style: normal;
    font-weight: 100;
    /* identical to box height, or 114% */
    padding-top: 1rem;
    letter-spacing: 0.05em;
    text-decoration-line: underline;
    color: @secondaryColor;

    &:hover {
      color: @primaryColor;
    }
  }
}
.form__title{
  font-family: 'Lora';
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 41px;
  /* identical to box height */
  color: @thirdGray;
  padding: 1.5rem 0rem;

}

.submit-button{
  &:hover {
    color:@lightGray;
  }

}

.mobile-only {
  display: initial;
}

.desktop-only {
  display: none;
}

// errored input
.ui.form .fields.error .field .input, .ui.form .field.error .input {
  & input {
    border: 1px solid red !important;
  }
}

@media (min-width: @tablet) {
  .desktop-only {
    display: initial;
  }

  .mobile-only {
    display: none;
  }

}

  .ui.form .field.field input:-webkit-autofill {
    background-color: @gray !important;
    border-color: @white !important;
    box-shadow: none !important;
  }

  .ui.icon.input > i.icon:not(.link) {
    filter: invert(1);
    opacity: 1;
  }

  .ui.form .fields .field .ui.input input, .ui.form .field .ui.input {
    input {
      background-color: @fifthGray !important;
      border-radius: 8px;
      color: black !important;
      margin-bottom: 0.5rem;
      border-color: transparent;
    }

    input::placeholder {
      color: white;
    }
}


.ui.labeled.icon.button:hover {
  background: @sixthGray;
  color: @white;
}

.ui.button {
  background: @white;
  color: @black;
  font-size: 0.7rem;
}

@media screen and (min-width: @mobile) {
  .ui.button {
    font-size: 0.9rem;
  }
}

.search--black .ui.button {
  background: @black;
}

.ui.inverted.yellow.button {
  box-shadow: 0px 0px 0px 2px @primaryColor inset !important;
  color: @primaryColor;
  padding: 0.6rem;

  &:hover {
    background: @primaryColor;
    color: white;
  }
}

.data-list {
  box-shadow: 0px 2px 4px 0px rgba(34, 36, 38, 0.12),
  0px 2px 10px 0px rgba(34, 36, 38, 0.15);
  .data-row {
    border: 1px solid #e0e0e0;
    font-size: 1rem;
  }
}



@media screen and(min-width: 960px) {
  .form {
    &--block {
      min-width: 450px;
    }
  }

  .table {
    &__data {
      &__field {
        width: 20rem;
      }
    }
  }

}

.create-song {
  .ui.list {
    margin: 0;
  }

  .ui.search.selection.dropdown > input.search, input:not([type]), .ui.selection.dropdown {
    background: rgba(220, 226, 225, 0.87) !important;
    min-width: 8.5rem;
  }

  .ui.form .fields .field .ui.input input, .ui.form .field .ui.input {
    input {
      background: rgba(220, 226, 225, 0.87) !important;
      color: black !important;
    }

    input::placeholder {
      color: gray;
    }
}
}

.landing__actions > * {
  margin: 1rem !important;
}

#fav-songs-logo {
  width: 26%;
}
