@import "../../variables.less";

.footer {
  align-items: center;
  color: @white;
  display: flex;
  justify-content: center;

  &__logo {
    width: 10rem;
    & img {
      height: auto;
    }
  }

  &__container {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content:space-between;
    padding: 1rem 0;
    width: 100%;

    & ul {
      display: flex;
      list-style: none;

      & * > {
        font-family: 'Nunito', sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        margin-left: 1rem;
      }
    }
  }

  &__text {
    font-family: 'Nunito', sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;

  }
}
