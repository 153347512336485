@import '../variables.less';

.track-switcher {
  display: flex;
  flex-wrap: nowrap;

  &__toggler-button {
    /* override semantic style */
    &.ui.button {
      background-color: transparent;
      border: 1px solid @mainGray;
      border-radius: 0;
      font-family: 'Nunito', sans-serif;
      font-size: .8rem;
      font-weight: 600;
      line-height: 1.14rem;
      letter-spacing: 0.05em;
      margin: 0;
      padding: auto 0.8rem;
      width: 33%;

      &:first-of-type {
        border-top-left-radius: 0.5rem;
        border-bottom-left-radius: 0.5rem;
        border-right: 0 !important;
      }

      &:last-of-type {
        border-top-right-radius: 0.5rem;
        border-bottom-right-radius: 0.5rem;
        border-left: 0 !important;
      }

      &:hover {
        background-color: @mainGray;
        color: white;
      }
    }

    &--selected.ui.button {
      background-color: @mainGray;
      color: white;
      &:hover {
        background-color: @mainGray;
      }
    }
  }
}

@media (min-width: @mobile) {
  .track-switcher {
    &__toggler-button {
      &.ui.button {
        font-size: 1rem;
        width: auto;
      }
    }
  }
}
