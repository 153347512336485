@import "../../variables.less";

.signup{
  &__button {
    width: 100%;
  }
  &__form {
    display: flex;
    flex-direction: column;
    justify-content: space-center;
  }

}
