@import '../../site/components/variables.less';

.virtual-table {
  color: #868686;

  .header-column { text-transform: none }
  .ReactVirtualized__Table__headerTruncatedText { white-space: normal; line-height: normal; }
  .ReactVirtualized__Table__Grid { background-color: @white; outline: none; }
  .data-row {
    align-items: center;
    display: flex;
    text-align: center;

    .ReactVirtualized__Table__rowColumn:first-of-type {
      text-align: left !important;
    }

    .ReactVirtualized__Table__rowColumn {
      align-items: center;
      display: flex;
      height: 100%;
    }
  }

  .ReactVirtualized__Table__headerRow {
    display: none;
  }

  .no-rows {
    position: absolute;
    top: 0; bottom: 0; left: 0; right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1em;
  }

  .no-wrap-header span {
    white-space: nowrap;
  }
}
