// here goes your global css
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,400;1,500;1,700;1,900&display=swap');

:root {
  --black:#1B1C1D;
  --gray: #545454;
  --dark-red: #9F3A38;
  --light-green: #6fc;
  --blue: #037575;
  --brown: #A52A2A;
  --olive: #808000;
}

body {
  font-family: 'Roboto', sans-serif;
  line-height: 1.7;
}

ul, ol {
  padding-inline-start: 1.2rem;
}

li {
  margin-bottom: 0;
}

.responsive-block(@suffix: ~'') {
  .mt-auto@{suffix} { margin-top: auto; }
  .flex@{suffix} { display: flex; }
  .outline@{suffix} { outline: unset; }
}

.responsive-block();
.responsive-block(-important) !important;

@media screen and (min-width: 768px) {
  .responsive-block(-ns);
  .responsive-block(-ns-important) !important;
}
@media screen and (min-width: 768px) and (max-width: 1200px) {
  .responsive-block(-m);
  .responsive-block(-m-important) !important;
}
@media screen and (min-width: 1200px) {
  .responsive-block(-l);
  .responsive-block(-l-important) !important;
}
