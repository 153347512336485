@primaryColor        : #F76700;
@primaryActive       : #A14300;
@secondaryColor      : #2F2F2F;
@mainGray            : #6C6C6C;
@gray: #f2f2f236;
@softGray: #F3F3F3;
@lightGray: #FFF3EB;
@white: white;
@black: @secondaryColor;
@tableHeader         : #e9ecf0;
@borderGray: #A3A3A3;
@borderPlayer: #727272;
@secondaryGray: #A5A29D;
@thirdGray: #474747;
@fourthGray: #D9D9D9;
@fifthGray: #F6F6F6;
@sixthGray: #5c5c5c;
@seventhGray: #404A5A;

@darkBackground: #00000059;
@brow: #474747;
@ellipsis: #F4C264BF;
@secondaryLight: #474747;
@yellowLight: #FFECC9;
@yellowGradient: linear-gradient(180deg, #FFCB13 0%, #FFB225 100%);
@yellowLight: #FFECC9;
@fileBorderColor: rgba(0, 0, 0, 0.08);
@wideScreenDesktop: 2500px;
@hugeDesktop: 2000px;
@bigDesktop: 1600px;
@desktop: 1441px;
@laptop: 1024px;
@tablet: 769px;
@mobile: 430px;
@bigMobile: 560px;

@superLight: 250;
@lighter: 300;
@light: 400;
@medium: 600;
@normal: 500;
@heavy: 700;
@bold: 800;
@bolder: 900;
@tinyFont: 0.5rem;
@smallerFont: 0.8rem;
@smallFont: 1rem;
@normalFont: 1.1rem;
@normalTwoFont: 1.25rem;
@mediumFont: 1.4rem;
@navFont: 1.6rem;
@bigFont: 1.7rem;
@biggerFont: 1.8rem;
@giantFont: 2.2rem;
@headerFont: 2.4rem;

:root {
  --black: @black;
  --gray: @gray;
  --primaryColor: @primaryColor;
  --lightPrimaryColor: #F4D4D5;
  --primaryHover: #D81F19;
  --mainGray: @mainGray;
  --lightGray: @lightGray;
  --white: @white;
  --borderGray: @borderGray;
  --tablet: @tablet;
  --mobile: @mobile;
  --bigMobile: @bigMobile;
 }
