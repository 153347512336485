@import '../../variables.less';

.button {
  border-radius: 40px;
  cursor: pointer;
  font-family: 'Nunito', sans-serif;
  font-size: 1.2rem;
  font-weight: 700;
  line-height: 1.9rem;
  padding: 1.05rem 4.5rem;

  &--sm {
    padding: 0.7rem 4.5rem;
  }

  &__width--sm {
    padding: 0.7rem 2.3rem
  }

  &__primary {
    background-color: @primaryColor;
    border: none;
    color: @white;
  }

  &__secondary {
    background-color: @secondaryColor;
    border: none;
    color: @white;
  }

  &__gray {
    background-color: @lightGray;
    border: 1px solid @primaryColor;
    color: @primaryColor;
  }

  &__secondary-gray {
    background-color: @secondaryGray;
    border: 1px solid @primaryActive;
    color: @primaryActive;
  }

  &__outline {
    &--primary {
      background-color: @white;
      border: 2px solid @primaryColor;
      color: @primaryColor;
    }
  }

  &--no-border {
    border: none;
  }
}
