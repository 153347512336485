
.video-player__no-screen {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.884);
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3rem 0;
  text-align: center;
  z-index: 0;

  &--message {
    animation: color-change 2s infinite;
  }
}

@keyframes color-change {
  0% { color: white; }
  50% { color: gray; }
  100% { color: lightgray; }
}
