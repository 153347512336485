@import "../../../components/variables.less";

.unallowed {
  &__buttonWhite{
    width: 100%;
  }
  &__buttonPrimary{
    width: 100%;
    margin-bottom: 0.5rem;
  }
  &__form {
    display: flex;
    flex-direction: column;
    justify-content: space-center;
  }
  &__description{
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 700;
    font-size: 1.7rem;
    line-height: 150%;
    /* identical to box height, or 36px */
    text-align: center;
    color: @mainGray;
    margin: 1rem 0rem;
    padding: 0rem;
  }
}
.close-modal{
  height: 1.5rem;
  float: right;
  margin-right:0.1rem;
  margin-top: 0.1rem;
}
.form-unallowed {
  &--block {
   padding: 0.5rem 0.5rem 0.5rem 0.5rem !important;
   max-width: 750px;
   background-color:@white;
   border-radius: 0.5rem;
   box-shadow: 0px 8px 24px @black;
   border: 1px solid @fourthGray;
  }
}
.elements{
  padding: 0rem 3rem 1rem 3rem;
}
