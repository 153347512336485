@import '../../../components/variables.less';

.song {

  .container {
    width:100%;
  }

  &__dropdown {
    background: @secondaryColor;
    border-radius: 30px;
    color: @white;
    padding: .3rem 2rem;
  }

  &__hero {
    display: flex;
    justify-content:space-between;
    flex-wrap: wrap;
    padding:0;
  }

  &__pdf-preview {
    align-items: center;
    display: flex;
    flex-direction: column;
    padding-bottom: 5rem;

    img {
      border: 1px solid @fourthGray;
      box-shadow: 0px .5rem 1.7rem @fileBorderColor;
      border-radius: .5rem;
      margin: 2rem 0;
      width: 100%;
    }

    .button {
      padding: 1rem 2rem;
    }
  }

  &__video {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
    margin-bottom: 0rem;
    width: 100%;

    &__header {
      margin-bottom: 2.5rem;
      display:none;

      & h1 {
        font-family: 'Lora', sans-serif;
        font-size: 2.2rem;
        font-weight: 400;
        line-height: 2.9rem;
        letter-spacing: 0em;
      }

      &__info {
        display: none;
      }
    }

    &__tab {
      width: 100%;
    }
  }

  &__tab__content {
    border: 3px solid @secondaryColor;
    height: 38rem;
    padding: .5rem;
  }

  &__pdf {
    height: 300px;
    margin: 5rem 0;
  }

  &__related {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    width: 95%;

    &__container {
      background-color: @yellowLight;
      padding: 1rem 0;
      width: 100%;
    }

    &__item {
      margin: 0 auto;
      width: 95%;

      & iframe {
        border-radius: 1.1rem;
        min-height: 198px;
      }
    }
  }

  &__info {

    p {
      font-family: 'Nunito', sans-serif;
      font-size: 1.1rem;
      font-weight: @light;
      line-height: 1.7rem;
      letter-spacing: 0em;
      padding-bottom: 1rem;
    }
    a {
      font-size: 1.1rem;
      color: @black;
      text-decoration: underline;
    }
    span{
      text-align: center;
      font-weight: bold;
    }
  }
}

@media (min-width: @mobile) {
  .song {
    &__related {
      flex-direction: row;
      width: 80%;

      &__item {
        margin: 0 1rem;
        width: 33%;
      }
    }
  }
}

@media (min-width: @tablet) {
  .song {

    &__video {
      width: 100%;

      &__tab {
        width: 35%;
      }
    }

    &__pdf-preview {
      img {
        margin: 2rem 2rem 2rem 0;
        width: 65%;
      }
    }
  }
}
